import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Background from '../../static/background-car.jpg';

const IndexPage = () => (

<div class="main-area center-text" style={{
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center center`,
  backgroundImage: `url(${Background})`,
}}>
  <div class="display-table">
    <div class="display-table-cell">
      <h1 class="title font-white"><b>4wheels24.co.uk - Coming Soon</b></h1>
      <p class="desc font-white">
        Our website is currently undergoing scheduled maintenance.
        We Should be back shortly. Thank you for your patience.
      </p>
    </div>
  </div>
</div>
)

const IndexPageTwo = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
